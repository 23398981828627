<template>
  <a-modal v-model="visible" :title="`Редактирование: ${form.ru ? form.ru.seo_description : ''}`" width="100%" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click="updateAds" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>

    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item class="text_area" label="Заголовок(ua)" required>
        <a-textarea allow-clear autoSize v-model="form.ua.seo_title"/>
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Описание(ua)" required>
        <a-textarea  allow-clear autoSize v-model="form.ua.seo_description"/>
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Заголовок(ru)">
        <a-textarea allow-clear autoSize v-model="form.ru.seo_title"/>
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Описание(ru)">
        <a-textarea allow-clear autoSize v-model="form.ru.seo_description"/>
      </a-form-model-item>

      <a-form-model-item label="Категория" required>
        <a-select v-model="form.category">
          <a-select-option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Тип недвижимости" required>
        <a-select v-model="form.realty_type">
          <a-select-option v-for="realtyType in realtyTypes" :key="realtyType.id" :value="realtyType.id">
            {{ realtyType.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Тип операций" required>
        <a-select v-model="form.operation_type">
          <a-select-option v-for="operationType in operationTypes" :key="operationType.id" :value="operationType.id">
            {{ operationType.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Количество комнат">
        <a-select v-model="form.apartment">
          <a-select-option v-for="apartment in apartments" :key="apartment.id" :value="apartment.id">
            {{ apartment.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Регион" required>
        <a-select v-model="form.region">
          <a-select-option v-for="region in regions" :key="region.id" :value="region.id">
            {{ region.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Город" required>
        <a-select v-model="form.city">
          <a-select-option v-for="city in cities" :key="city.id" :value="city.id">
            {{ city.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Район">
        <a-select v-model="form.district">
          <a-select-option v-for="district in districts" :key="district.id" :value="district.id">
            {{ district.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Станция">
        <a-select v-model="form.station">
          <a-select-option v-for="station in stations" :key="station.id" :value="station.id">
            {{ station.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Этажность">
        <a-select v-model="form.type_floor">
          <a-select-option v-for="typeFloor in typeFloors" :key="typeFloor.id" :value="typeFloor.id">
            {{ typeFloor.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Этаж">
        <a-select v-model="form.floor">
          <a-select-option v-for="floor in floors" :key="floor.id" :value="floor.id">
            {{ floor.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Отопление">
        <a-select v-model="form.heating">
          <a-select-option v-for="heating in heatings" :key="heating.id" :value="heating.id">
            {{ heating.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Тип жилья">
        <a-select v-model="form.life_type">
          <a-select-option v-for="lifeType in lifeTypes" :key="lifeType.id" :value="lifeType.id">
            {{ lifeType.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="Тип стен">
        <a-select v-model="form.type_wall">
          <a-select-option v-for="wallType in wallTypes" :key="wallType.id" :value="wallType.id">
            {{ wallType.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item />

      <a-form-model-item label="Планировка">
        <a-checkbox-group
          v-model="form.layouts"
          name="checkboxgroup"
          :options="layouts"
        />
      </a-form-model-item>

      <a-form-model-item />
      <!-- <a-form-model-item label="Год выпуска" required>
        <a-input v-model="form.year_begin"/>
      </a-form-model-item> -->
    </a-form-model>
  </a-modal>
</template>

<script>

const initailForm = {
  category: null,
  apartment: null,
  region: null,
  city: null,
  district: null,
  station: null,
  type_floor: null,
  floor: null,
  heating: null,
  life_type: null,
  operation_type: null,
  realty_type: null,
  type_wall: null,
  layouts: [],
  ua: {},
  ru: {},
}

export default {
  name: 'EditModal',

  data() {
    return {
      visible: false,
      product: {},
      id: '',
      form: { ...initailForm },
      categories: [],
      apartments: [],
      regions: [],
      cities: [],
      districts: [],
      stations: [],
      typeFloors: [],
      floors: [],
      heatings: [],
      lifeTypes: [],
      operationTypes: [],
      realtyTypes: [],
      wallTypes: [],
      layouts: [],

      flag: false,
      isValid: false,
      rules: {
        active: [
          {
            required: true,
            message: 'Пожалуста введите статус',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  computed: {
    isDisabled() {
      const form = this.form
      return !(form.ua.seo_title && form.ua.seo_description && form.ru.seo_title && form.ru.seo_description && form.category && form.realty_type && form.operation_type && form.region && form.city)
    },
  },

  methods: {
    async open(id) {
      this.visible = true
      this.id = id
      const form = (await this.$services.get(`admin/products/edit/${id}`)).data.data.product

      this.form.category = form.category
      this.form.apartment = form.apartment
      this.form.region = form.region
      this.form.city = form.city
      this.form.district = form.district
      this.form.station = form.station
      this.form.type_floor = form.type_floor
      this.form.floor = form.floor
      this.form.heating = form.heating
      this.form.life_type = form.life_type
      this.form.operation_type = form.operation_type
      this.form.realty_type = form.realty_type
      this.form.type_wall = form.type_wall
      this.form.ru = form.ru
      this.form.ua = form.ua
      this.form.layouts = form.layouts.map((l) => l.id)
      this.form.is_blocked = form.is_blocked

      await Promise.all([
        this.getCategories(),
        this.getApartments({
          category_id: this.form.category,
        }),
        this.getRegions(),
        this.getCities({
          region_id: this.form.region,
        }),
        this.getDistricts({
          city_id: this.form.city,
        }),
        this.getStations({
          city_id: this.form.city,
        }),
        this.getTypeFloors({
          category_id: this.form.category,
        }),
        this.getFloors({
          category_id: this.form.category,
        }),
        this.getHeatings({
          category_id: this.form.category,
        }),

        this.getLifeTypes({
          category_id: this.form.category,
        }),
        this.getOperationTypes({
          category_id: this.form.category,
        }),
        this.getRealtyTypes({
          category_id: this.form.category,
        }),
        this.getWallTypes({
          category_id: this.form.category,
        }),
        this.getLayouts({
          category_id: this.form.category,
        }),
      ])

      this.$nextTick(() => {
        this.flag = true
      })
    },

    async getCategories() {
      try {
        this.categories = (await this.$services.get('filters/categories')).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getApartments(params) {
      try {
        this.apartments = (await this.$services.get('filters/apartments', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getRegions() {
      try {
        this.regions = (await this.$services.get('filters/regions')).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getCities(params) {
      try {
        this.cities = (await this.$services.get('filters/cities', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getDistricts(params) {
      try {
        this.districts = (await this.$services.get('filters/districts', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getStations(params) {
      try {
        this.stations = (await this.$services.get('filters/stations', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getTypeFloors(params) {
      try {
        this.typeFloors = (await this.$services.get('filters/type_floors', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getFloors(params) {
      try {
        this.floors = (await this.$services.get('filters/floors', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getHeatings(params) {
      try {
        this.heatings = (await this.$services.get('filters/heatings', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getLifeTypes(params) {
      try {
        this.lifeTypes = (await this.$services.get('filters/life_types', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getOperationTypes(params) {
      try {
        this.operationTypes = (await this.$services.get('filters/operation_types', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getRealtyTypes(params) {
      try {
        this.realtyTypes = (await this.$services.get('filters/realty_types', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getWallTypes(params) {
      try {
        this.wallTypes = (await this.$services.get('filters/type_walls', { params })).data.data.values
      } catch (e) {
        console.error(e)
      }
    },

    async getLayouts(params) {
      try {
        const layouts = (await this.$services.get('filters/layouts', { params })).data.data.values
        this.layouts = layouts.map((l) => ({ label: l.name, value: l.id }))
      } catch (e) {
        console.error(e)
      }
    },

    async updateAds() {
      const payload = { ...this.form }

      try {
        await this.$services.put(`admin/products/${this.id}`, { ...payload })
        this.visible = false
      } catch (e) {
        console.log(e)
      }
    },

    close() {
      this.visible = false
      this.form = { ...initailForm }
      this.flag = false
    },
  },

  watch: {
    async 'form.category'() {
      if (this.flag && this.form.category) {
        this.form.apartment = null
        this.form.type_floor = null
        this.form.floor = null
        this.form.heating = null
        this.form.life_type = null
        this.form.operation_type = null
        this.form.realty_type = null
        this.form.type_wall = null
        this.form.layouts = []

        await Promise.all([
          this.getApartments({
            category_id: this.form.category,
          }),
          this.getTypeFloors({
            category_id: this.form.category,
          }),
          this.getFloors({
            category_id: this.form.category,
          }),
          this.getHeatings({
            category_id: this.form.category,
          }),
          this.getLifeTypes({
            category_id: this.form.category,
          }),
          this.getOperationTypes({
            category_id: this.form.category,
          }),
          this.getRealtyTypes({
            category_id: this.form.category,
          }),
          this.getWallTypes({
            category_id: this.form.category,
          }),
          this.getLayouts({
            category_id: this.form.category,
          }),
        ])
      }
    },

    async 'form.region'(regionId) {
      if (this.flag && regionId) {
        this.form.city = null
        this.form.district = null
        this.form.station = null
        this.districts = []
        this.stations = []
        await this.getCities({
          region_id: regionId,
        })
      }
    },

    async 'form.city'(cityId) {
      if (this.flag && cityId) {
        this.form.district = null
        this.form.station = null
        await this.getDistricts({
          city_id: cityId,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-modal {
  padding: 24px 16px;
  .ant-modal-title {
    font-weight: bolder;
    font-size: 24px;
  }
}
.ant-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .ant-form-item {
    width: calc(33% - 21px);
  }
  ::v-deep label {
    margin-bottom: 0;
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  ::v-deep .checkbox {
    width: 100%;
    margin-bottom: 10px;
  }
  ::v-deep .text_area {
    width: calc(50% - 16px);
  }
}
</style>
