<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <span
      slot="id"
      slot-scope="text"
    >
      {{text}}
    </span>
    <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
    <span slot="unique_id" slot-scope="record">
      <a :href="getProductLink(record.alias)" target="_blank">{{ record.unique_id }}</a>
    </span>
    <span slot="price" slot-scope="record">{{ record.currency_default }} {{ record.price }}</span>
    <span slot="user" slot-scope="record">{{ record.user }}</span>
    <span slot="date" slot-scope="record">
      <template v-if="record.updated_at">
        {{ formatDate(record.updated_at) }}
      </template>
    </span>
    <span slot="action" slot-scope="record" class="h-flex">
      <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 h-flex" @click="$emit('editItem', record.id)">
        <i class="fe fe-edit mr-2" />
        Изменить
      </a>
      <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 h-flex" @click="$emit('toPreview', record.id)">
        <small>
          <i class="fe fe-more-horizontal mr-2" />
        </small>
        Просмотр
      </a>
      <a-popconfirm
        title="Вы уверенны что хотите удалить?"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="$emit('removeItem', record)"
      >
        <a href="javascript: void(0);" class="btn btn-sm btn-light h-flex">
          <small>
            <i class="fe fe-trash mr-2" />
          </small>
          Удалить
        </a>
      </a-popconfirm>
    </span>
  </a-table>
</template>

<script>
import { getFrontUrl } from '@/utils'
import { formatDate } from '../../utils'

export default {
  name: 'ProductsTable',

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
    types: {
      type: Array,
      default: () => {
        return []
      },
    },
    fromClient: {
      type: Boolean,
      default: false,
    },
    isOverview: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      columns: [],
    }
  },

  created() {
    this.setColumns()
  },

  watch: {
    list: {
      handler() {
        this.setColumns()
      },
      deep: true,
    },
  },

  methods: {
    formatDate,

    getCurrentType(id) {
      const type = this.types.find(item => item.id === id)
      return type ? type.name : ''
    },

    setColumns() {
      this.columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Уникальный ID',
          scopedSlots: { customRender: 'unique_id' },
        },
        {
          title: 'Цена',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: 'Продавец',
          dataIndex: 'user',
        },
        {
          title: 'Последние изменения',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ]
      if (this.fromClient) this.columns.splice(4, 1)
    },

    getProductLink(alias) {
      return `${getFrontUrl()}/product/${alias}`
    },
  },
}
</script>
