<template>
  <a-modal v-model="visible" :title="`Просмотр: ${form.name}`" width="100%" :afterClose="close">
    <div class="info_container">
      <div class="main_img">
        <div>
          <img :src="form.mainImage" alt="main image"/>
        </div>
      </div>
      <div class="info_container-item name">
        <h3>Название</h3>
        <span>{{ form.name }}</span>
      </div>
      <div class="info_container-item name">
        <h3>Описание</h3>
        <span>{{ form.description }}</span>
      </div>

      <div class="info_container-item">
        <h3>Уникальный ID</h3>
        <span>{{ form.unique_id }}</span>
      </div>
      <div class="info_container-item">
        <h3>Дата создания</h3>
        <span>{{ form.created_at }}</span>
      </div>
      <div class="info_container-item">
        <h3>Категория</h3>
        <span>{{ form.category }}</span>
      </div>
      <div class="info_container-item">
        <h3>Количество комнат</h3>
        <span>{{ form.apartment }}</span>
      </div>
      <div class="info_container-item">
        <h3>Регион</h3>
        <span>{{ form.region }}</span>
      </div>
      <div class="info_container-item">
        <h3>Город</h3>
        <span>{{ form.city }}</span>
      </div>
      <div class="info_container-item">
        <h3>Район</h3>
        <span>{{ form.district }}</span>
      </div>
      <div class="info_container-item">
        <h3>Станция</h3>
        <span>{{ form.station }}</span>
      </div>
      <div class="info_container-item">
        <h3>Этажность</h3>
        <span>{{ form.type_floor }}</span>
      </div>
      <div class="info_container-item">
        <h3>Этаж</h3>
        <span>{{ form.floor }}</span>
      </div>
      <div class="info_container-item">
        <h3>Отопление</h3>
        <span>{{ form.heating }}</span>
      </div>
      <div class="info_container-item">
        <h3>Тип жилья</h3>
        <span>{{ form.life_type }}</span>
      </div>
      <div class="info_container-item">
        <h3>Тип операций</h3>
        <span>{{ form.operation_type }}</span>
      </div>
      <div class="info_container-item">
        <h3>Тип недвижимости</h3>
        <span>{{ form.realty_type }}</span>
      </div>
      <div class="info_container-item">
        <h3>Тип стен</h3>
        <span>{{ form.type_wall }}</span>
      </div>
    </div>

    <div class="info_container start">
      <div class="info_container-item">
        <h3>Планировка</h3>
        <ul>
          <li v-for="l of form.layouts" :key="l.id">
            <span>{{ l.name }}</span>
          </li>
        </ul>
      </div>
    </div>

    <template slot="footer">
      <a-button key="back" @click="close">
        Ok
      </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'PreviewModal',

  data() {
    return {
      visible: false,
      form: {},
    }
  },

  methods: {
    async open(id) {
      this.visible = true
      try {
        this.form = (await this.$services.get(`admin/products/${id}`)).data.data.product
      } catch (e) {
        console.log(e)
      }
    },

    close() {
      this.visible = false
      this.product = {}
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-modal {
  padding: 24px 16px;
  .ant-modal-title {
    font-weight: bolder;
    font-size: 24px;
  }
}
.info_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &.start {
    justify-content: flex-start;
  }
  &-item {
    width: calc(33% - 21px);
    margin-bottom: 24px;
    h3 {
      font-size: 20px;
    }
    span {
      font-size: 16px;
    }
    .color_image {
      width: 24px;
      height: 24px;
      margin-left: 12px;
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .name {
    text-align: center;
    width: 100%;
  }
  .main_img {
    width: 100%;
    padding: 24px 0;
    div {
      width: 500px;
      height: auto;
      margin: 0 auto;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

</style>
